(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/games-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/games-reducer.js');
"use strict";

function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  FETCH_ACTIVE_GAMES_SUCCESS
} = svs.components.marketplaceRetailAdminData.createGameTemplate.actions;
const {
  FETCH_NEXT_RETAILERS_AND_TEAMS_SUCCESS,
  FETCH_ALL_RETAILERS_FINISHED,
  FETCH_TEAM_INIT,
  END_TEAM_SUCCESS
} = svs.components.marketplaceData.teams.actions;
const {
  payFraction
} = svs.components.marketplaceData.fractionPayment.actions;
const {
  GAME_FETCH_INIT,
  GAME_FETCH_FAILURE,
  GAME_FETCH_SUCCESS,
  ACTIVATED_DRAW_FETCH_INIT,
  ACTIVATED_DRAW_FETCH_SUCCESS,
  ACTIVATED_DRAW_FETCH_FAILURE,
  GAMES_FETCH_INIT,
  GAMES_FETCH_SUCCESS,
  GAMES_FETCH_FAILURE,
  GAMES_HISTORY_FETCH_SUCCESS,
  REMOVE_GAME_INIT,
  REMOVE_GAME_FAILED,
  REMOVE_GAME_SUCCESS,
  FETCH_SETTLED,
  FETCH_SETTLED_SUCCESS
} = svs.components.marketplaceData.games.actions;
const {
  GAME_FETCH_MOVED_TO_SETTLED
} = svs.components.marketplaceRetailAdminData.games.actions;
const {
  TEAM_CREATE_SUCCESS
} = svs.components.marketplaceData.createTeam.actions;
const {
  CREATE_GAME_SUCCESS,
  UPDATE_GAME_SUCCESS,
  FETCH_GAME_SUCCESS: CREATE_FETCH_GAME_SUCCESS
} = svs.components.marketplaceData.createGame.actions;
const {
  REMOVE_GAME_SUCCESS: CREATE_GAME_REMOVE_GAME_SUCCESS
} = svs.components.marketplaceRetailAdminData.createGame.actions;
const {
  MEMBER_LEAVE_TEAM_SUCCESS
} = svs.components.marketplaceData.members.actions;
const {
  parseEditorState
} = svs.components.marketplaceRetailAdmin.richText.helpers;
const reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case FETCH_ACTIVE_GAMES_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.groupId]: _objectSpread(_objectSpread({}, state[action.groupId] || {}), {}, {
          data: action.games
        })
      });
    case FETCH_NEXT_RETAILERS_AND_TEAMS_SUCCESS:
      {
        const teamSubStates = action.teams.reduce((acc, team) => _objectSpread(_objectSpread({}, acc), {}, {
          [team.id]: {
            error: null,
            data: team.games,
            loading: false
          }
        }), {});
        const stateKeys = Object.keys(state);
        const reducedState = stateKeys.length > 0 ? stateKeys.reduce((acc, key) => ({
          [state[key]]: {
            data: _objectSpread({}, teamSubStates[key] ? teamSubStates[key].data : state[key] ? state[key].data : undefined)
          }
        })) : state;
        return _objectSpread(_objectSpread({}, _objectSpread(_objectSpread({}, state), teamSubStates)), Array.isArray(reducedState) ? reducedState : []);
      }
    case FETCH_ALL_RETAILERS_FINISHED:
      {
        const retailSubStates = action.retailers.reduce((acc, team) => _objectSpread(_objectSpread({}, acc), {}, {
          [team.id]: {
            error: null,
            data: team.games,
            loading: false
          }
        }), {});
        return _objectSpread(_objectSpread({}, retailSubStates), state);
      }
    case FETCH_TEAM_INIT:
      return _objectSpread({
        [action.groupId || action.groupId]: {
          error: null,
          data: [],
          loading: false
        }
      }, state);
    case CREATE_GAME_SUCCESS:
      {
        if (action.game && action.game.isRetail) {
          return state;
        } 
        return _objectSpread(_objectSpread({}, state), {}, {
          [action.groupId]: _objectSpread(_objectSpread({}, state[action.groupId]), {}, {
            data: (state[action.groupId].data || []).concat(action.game)
          })
        });
      }
    case payFraction.fulfilled:
      {
        const games = (state[action.groupId] || {}).data || [];
        const game = games.find(game => game.activatedDrawId === action.activatedDrawId) || {};
        const gameFractions = game.fractions || {};
        const gameIndex = games.indexOf(game);
        const updatedGame = _objectSpread(_objectSpread({}, game), {}, {
          fractionsCount: game.fractionsCount + action.fractionsBought,
          fractions: _objectSpread(_objectSpread({}, game.fractions), {}, {
            ["".concat(action.memberId)]: (gameFractions[action.memberId] || 0) + action.fractionsBought
          })
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          [action.groupId]: _objectSpread(_objectSpread({}, state[action.groupId]), {}, {
            data: [...games.slice(0, gameIndex), updatedGame, ...games.slice(gameIndex + 1)]
          })
        });
      }
    case GAMES_FETCH_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.groupId]: _objectSpread(_objectSpread({}, state[action.groupId]), {}, {
          error: null,
          loading: true
        })
      });
    case GAMES_HISTORY_FETCH_SUCCESS:
    case GAMES_FETCH_SUCCESS:
      {
        const _processGamesWithKeepingOrder = oldGamesList => {
          const oldIds = oldGamesList.map(item => item.activatedDrawId);
          const updatedGamesList = action.games.filter(item => oldIds.includes(item.activatedDrawId));
          const addedGamesList = action.games.filter(item => !oldIds.includes(item.activatedDrawId));
          return [...oldGamesList.map(item => updatedGamesList.find(_ref => {
            let {
              activatedDrawId
            } = _ref;
            return activatedDrawId === item.activatedDrawId;
          }) || item), ...addedGamesList];
        };
        const currState = state[action.groupId] || {};
        const data = _processGamesWithKeepingOrder(currState.data || []);
        const activeGamesFetched = action.type === GAMES_FETCH_SUCCESS ? action.activeGamesFetched : currState.activeGamesFetched || 0;
        const activeGamesFetchable = action.type === GAMES_FETCH_SUCCESS ? action.activeGamesFetchable : currState.activeGamesFetchable || 0;
        return _objectSpread(_objectSpread({}, state), {}, {
          [action.groupId]: {
            loading: false,
            data,
            activeGamesFetched,
            activeGamesFetchable
          }
        });
      }
    case GAMES_FETCH_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.groupId]: _objectSpread(_objectSpread({}, state[action.groupId] || {}), {}, {
          error: action.error,
          loading: false
        })
      });
    case ACTIVATED_DRAW_FETCH_INIT:
    case FETCH_SETTLED:
    case GAME_FETCH_INIT:
      {
        const subState = state[action.groupId] || {
          data: []
        };
        return _objectSpread(_objectSpread({}, state), {}, {
          [action.groupId]: _objectSpread(_objectSpread({}, subState), {}, {
            error: null,
            loading: true
          })
        });
      }
    case ACTIVATED_DRAW_FETCH_FAILURE:
    case GAME_FETCH_FAILURE:
      {
        const subState = state[action.groupId] || {
          data: []
        };
        return _objectSpread(_objectSpread({}, state), {}, {
          [action.groupId]: _objectSpread(_objectSpread({}, subState), {}, {
            error: action.error,
            loading: false
          })
        });
      }
    case FETCH_SETTLED_SUCCESS:
      {
        const teamState = state[action.groupId] || {
          data: []
        };
        const hasGame = teamState.data.find(game => game.activatedDrawId === action.activatedDrawId);
        return _objectSpread(_objectSpread({}, state), {}, {
          [action.groupId]: _objectSpread(_objectSpread({}, teamState), {}, {
            data: hasGame ? teamState.data.map(game => {
              if (game.activatedDrawId !== action.activatedDrawId) {
                return game;
              }
              return action.game;
            }) : [...teamState.data, action.game],
            loading: false
          })
        });
      }
    case ACTIVATED_DRAW_FETCH_SUCCESS:
    case CREATE_FETCH_GAME_SUCCESS: 
    case GAME_FETCH_SUCCESS:
      {
        const {
          activatedDrawId,
          containerId,
          game,
          groupId
        } = action;
        const teamState = state[groupId] || {
          data: []
        };
        let hasFoundGame = false;
        let data = teamState.data.map(g => {
          if (activatedDrawId && g.activatedDrawId === parseInt(activatedDrawId, 10)) {
            hasFoundGame = true;
            return game;
          }
          if (!activatedDrawId && g.containerId === parseInt(containerId, 10)) {
            hasFoundGame = true;
            return game;
          }
          return g;
        });
        if (!hasFoundGame) {
          data.push(game);
        }
        data = data.map(d => isJson(d.description) ? _objectSpread(_objectSpread({}, d), {}, {
          richDescription: parseEditorState(d.description)
        }) : d);
        return _objectSpread(_objectSpread({}, state), {}, {
          [groupId]: _objectSpread(_objectSpread({}, teamState), {}, {
            data,
            loading: false
          })
        });
      }
    case GAME_FETCH_MOVED_TO_SETTLED:
      {
        const {
          activatedDrawId,
          groupId
        } = action;
        const currGamesState = state[groupId] || {
          data: []
        };
        const data = currGamesState.data.filter(item => item.activatedDrawId !== activatedDrawId);
        data.push({
          activatedDrawId,
          isMovedToSettled: true
        });
        return _objectSpread(_objectSpread({}, state), {}, {
          [groupId]: _objectSpread(_objectSpread({}, currGamesState), {}, {
            data,
            loading: false
          })
        });
      }
    case TEAM_CREATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.team.id]: {
          data: []
        }
      });
    case REMOVE_GAME_INIT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          [action.groupId]: _objectSpread(_objectSpread({}, state[action.groupId]), {}, {
            removing: {
              status: 'removing',
              activatedDrawId: action.activatedDrawId
            }
          })
        });
      }
    case REMOVE_GAME_FAILED:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          [action.groupId]: _objectSpread(_objectSpread({}, state[action.groupId]), {}, {
            removing: {
              activatedDrawId: action.activatedDrawId,
              status: 'failed'
            },
            error: action.error
          })
        });
      }
    case CREATE_GAME_REMOVE_GAME_SUCCESS:
    case REMOVE_GAME_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          [action.groupId]: {
            data: state[action.groupId].data.filter(game => game.activatedDrawId !== action.activatedDrawId),
            removing: {
              activatedDrawId: action.activatedDrawId,
              status: 'success'
            }
          }
        });
      }
    case MEMBER_LEAVE_TEAM_SUCCESS:
      {
        if (action.isParticipatingInGame) {
          return _objectSpread(_objectSpread({}, state), {}, {
            [action.groupId]: _objectSpread(_objectSpread({}, state[action.groupId]), {}, {
              data: state[action.groupId].data.filter(game => (game.fractions[action.memberId] || 0) > 0)
            })
          });
        }
        const _ref2 = "".concat(action.groupId),
          {
            [_ref2]: _
          } = state,
          rest = _objectWithoutProperties(state, [_ref2].map(_toPropertyKey));
        return rest;
      }
    case END_TEAM_SUCCESS:
      {
        const _ref3 = "".concat(action.groupId),
          {
            [_ref3]: _
          } = state,
          rest = _objectWithoutProperties(state, [_ref3].map(_toPropertyKey));
        return rest;
      }
    case UPDATE_GAME_SUCCESS:
      {
        const groupId = action.groupId || action.game.groupId;
        return _objectSpread(_objectSpread({}, state), {}, {
          [groupId]: _objectSpread(_objectSpread({}, state[groupId]), {}, {
            data: state[groupId].data.map(game => game.activatedDrawId === action.game.activatedDrawId ? _objectSpread(_objectSpread({}, game), action.game) : game)
          })
        });
      }
    default:
      return state;
  }
};
function isJson(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
setGlobal('svs.components.marketplaceData.store.reducers.gamesReducer', reducer);

 })(window);