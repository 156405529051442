(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/bet-proposal-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/bet-proposal-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const {
    FETCH_BET_PROPOSAL_INIT,
    FETCH_BET_PROPOSAL_SUCCESS,
    FETCH_BET_PROPOSAL_FAILURE
  } = svs.components.marketplaceData.betProposal.actions;
  const {
    BET_PROPOSAL_UPDATE_SUCCESS,
    BET_PROPOSAL_CREATE_SUCCESS
  } = svs.components.marketplaceData.createBetProposal.actions;
  switch (action.type) {
    case FETCH_BET_PROPOSAL_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.activatedDrawId]: _objectSpread(_objectSpread({}, state[action.activatedDrawId]), {}, {
          loading: true,
          error: undefined,
          isPrivate: undefined
        })
      });
    case FETCH_BET_PROPOSAL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.activatedDrawId]: {
          data: action.betProposal,
          loading: false,
          error: undefined,
          isPrivate: action.isPrivate
        }
      });
    case FETCH_BET_PROPOSAL_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.activatedDrawId]: _objectSpread(_objectSpread({}, state[action.activatedDrawId]), {}, {
          loading: false,
          error: action.error,
          isPrivate: undefined,
          data: []
        })
      });
    case BET_PROPOSAL_CREATE_SUCCESS:
    case BET_PROPOSAL_UPDATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.activatedDrawId]: {
          data: action.betProposal
        }
      });
    default:
      return state;
  }
};
setGlobal('svs.components.marketplaceData.store.reducers.betProposalReducer', reducer);

 })(window);