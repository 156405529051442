(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/browse-games-filter-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/browse-games-filter-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  getProducts
} = svs.components.marketplace.helpers;
const {
  paramHandler
} = svs.utils;
const {
  FILTER_UPDATE,
  LOAD_QUERYSTRING
} = svs.components.marketplaceData.browseGames.actions;
const {
  FILTER_TOGGLE_PRODUCT,
  FILTER_FRACTION_PRICE,
  FILTER_FRACTION_COUNT,
  FILTER_SYSTEM_PRICE,
  FILTER_QUERY_NAME,
  CLEAR_FILTER
} = svs.components.marketplaceData.browseGames.helpers.constants;
const searchQueryFromURL = paramHandler.get({
  key: 'search'
}).value;
const initialState = {
  searchName: searchQueryFromURL || '',
  products: getProducts().reduce((acc, product) => _objectSpread(_objectSpread({}, acc), {}, {
    [product]: false
  }), {}),
  subProducts: getProducts().reduce((acc, product) => _objectSpread(_objectSpread({}, acc), {}, {
    [product]: []
  }), {}),
  fractionPriceMin: 10,
  fractionPriceMax: 1000,
  fractionPriceMinDisplay: 10,
  fractionPriceMaxDisplay: 1000,
  fractionCountMin: 2,
  fractionCountMax: 50,
  fractionCountMinDisplay: 2,
  fractionCountMaxDisplay: 50,
  systemPriceMin: 20,
  systemPriceMax: 42000,
  systemPriceMinDisplay: 20,
  systemPriceMaxDisplay: 42000,
  hasActiveFilter: false,
  hasActiveFilterParameters: false
};
const isAnyFilterParametersActive = state => {
  for (const key in state.products) {
    if (state.products[key]) {
      return true;
    }
  }
  if (state.fractionPriceMin !== initialState.fractionPriceMin || state.fractionPriceMax !== initialState.fractionPriceMax || state.fractionPriceMaxDisplay !== initialState.fractionPriceMaxDisplay || state.fractionCountMin !== initialState.fractionCountMin || state.fractionCountMax !== initialState.fractionCountMax || state.systemPriceMin !== initialState.systemPriceMin || state.systemPriceMax !== initialState.systemPriceMax) {
    return true;
  }
  return false;
};
function hasActiveFilter(state) {
  const hasActiveFilterParameters = isAnyFilterParametersActive(state);
  const hasActiveFilter = hasActiveFilterParameters || state.searchName !== '';
  return _objectSpread(_objectSpread({}, state), {}, {
    hasActiveFilter,
    hasActiveFilterParameters
  });
}
const filterQueryName = (state, value) => hasActiveFilter(_objectSpread(_objectSpread({}, state), {}, {
  searchName: value.name
}));
const filterToggleProduct = (state, value) => {
  const productSubProducts = state.subProducts[value.product];
  let newProductSubProducts = productSubProducts;
  if (value.subProductId) {
    newProductSubProducts = productSubProducts.includes(value.subProductId) ? productSubProducts.filter(p => p !== value.subProductId) : [...productSubProducts, value.subProductId];
  }
  return hasActiveFilter(_objectSpread(_objectSpread({}, state), {}, {
    subProducts: _objectSpread(_objectSpread({}, state.subProducts), {}, {
      [value.product]: newProductSubProducts
    }),
    products: Object.keys(state.products).reduce((acc, product) => {
      if (product === value.product) {
        acc[product] = newProductSubProducts.length ? true : !state.products[product];
      } else {
        acc[product] = state.products[product];
      }
      return acc;
    }, {})
  }));
};
const filterFractionPrice = (state, value) => hasActiveFilter(_objectSpread(_objectSpread({}, state), {}, {
  fractionPriceMin: value.selectedMin,
  fractionPriceMax: value.selectedMax
}));
const filterFractionCount = (state, value) => hasActiveFilter(_objectSpread(_objectSpread({}, state), {}, {
  fractionCountMin: value.selectedMin,
  fractionCountMax: value.selectedMax
}));
const filterSystemPrice = (state, value) => hasActiveFilter(_objectSpread(_objectSpread({}, state), {}, {
  systemPriceMin: value.selectedMin,
  systemPriceMax: value.selectedMax
}));
const clearFilter = state => _objectSpread(_objectSpread({}, initialState), {}, {
  searchName: state.searchName
});
const reducer = function () {
  var _action$updatedFilter;
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const value = action === null || action === void 0 || (_action$updatedFilter = action.updatedFilter) === null || _action$updatedFilter === void 0 ? void 0 : _action$updatedFilter.value;
  switch (action.type) {
    case FILTER_UPDATE:
      switch (action.updatedFilter.key) {
        case FILTER_QUERY_NAME:
          return filterQueryName(state, value);
        case FILTER_TOGGLE_PRODUCT:
          return filterToggleProduct(state, value);
        case FILTER_FRACTION_PRICE:
          return filterFractionPrice(state, value);
        case FILTER_FRACTION_COUNT:
          return filterFractionCount(state, value);
        case FILTER_SYSTEM_PRICE:
          return filterSystemPrice(state, value);
        case CLEAR_FILTER:
          return clearFilter(state);
        default:
          return hasActiveFilter(_objectSpread({}, state));
      }
    case LOAD_QUERYSTRING:
      return hasActiveFilter(_objectSpread(_objectSpread(_objectSpread({}, state), action.data), {}, {
        subProducts: _objectSpread(_objectSpread({}, state.subProducts), action.data.subProducts),
        products: Object.keys(state.products).reduce((acc, product) => {
          if (action.data.products.find(item => item === product)) {
            acc[product] = !state.products[product];
          } else {
            acc[product] = state.products[product];
          }
          return acc;
        }, {})
      }));
    default:
      return state;
  }
};
setGlobal('svs.components.marketplaceData.store.reducers.browseGamesFilterReducer', reducer);

 })(window);