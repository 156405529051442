(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/create-game-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/create-game-reducer.js');
"use strict";

var _svs, _svs2;
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  FETCH_ALL_DRAWS_SUCCESS
} = svs.components.marketplaceData.draws.actions;
const {
  SELECT_PRODUCT,
  SELECT_DRAW,
  CREATE_GAME,
  CREATE_GAME_FAILED,
  CREATE_GAME_SUCCESS,
  CLEAR_FORM,
  FETCH_GAME,
  FETCH_GAME_FAILED,
  FETCH_GAME_SUCCESS,
  CHANGE_BUY_STOP_DIFF,
  CHANGE_FRACTION_PRICE,
  CHANGE_DESCRIPTION,
  CHANGE_MAX_FRACTIONS_PER_MEMBER,
  CHANGE_MAX_FRACTIONS_PER_GAME,
  UPDATE_GAME,
  UPDATE_GAME_FAILED,
  UPDATE_GAME_SUCCESS,
  SELECTED_DRAW_CLOSED,
  RESET_VALUES
} = svs.components.marketplaceData.createGame.actions;
const {
  REMOVE_GAME_SUCCESS
} = svs.components.marketplaceData.games.actions;
const {
  LOAD_FROM_LOCAL_STORAGE
} = svs.components.marketplaceData.localStorage.actions;
const {
  getProducts
} = svs.components.marketplace.helpers;
const SECONDS = 1000;
const MINUTES = 60;
const DEFAULT_BUY_STOP = 0;
const DEFAULT_MAX_FRACTIONS_PER_MEMBER = 1;
const DEFAULT_MAX_FRACTIONS_PER_GAME = 50;

const defaultBuyStopDiff = ((_svs = svs) === null || _svs === void 0 || (_svs = _svs.marketplace) === null || _svs === void 0 ? void 0 : _svs.data.initialState.Config.selectedBuyStop) || ((_svs2 = svs) === null || _svs2 === void 0 || (_svs2 = _svs2.marketplace_retail_admin) === null || _svs2 === void 0 ? void 0 : _svs2.data.initialState.Config.selectedBuyStop) || DEFAULT_BUY_STOP;
const initialState = {
  availableProducts: [...getProducts()],
  isLoading: false,
  loadingGame: false,
  selectedDraw: 0,
  selectedProduct: '',
  description: '',
  fractionPrice: undefined,
  maxFractionsPerMember: DEFAULT_MAX_FRACTIONS_PER_MEMBER,
  maxFractionsPerGame: DEFAULT_MAX_FRACTIONS_PER_GAME,
  isSelectedDrawClosed: false,
  buyStopDiff: defaultBuyStopDiff
};
const reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  switch (action.type) {
    case FETCH_ALL_DRAWS_SUCCESS:
      {
        const selectedProduct = state.selectedProduct && action.productDraws[state.selectedProduct] && Boolean(action.productDraws[state.selectedProduct].draws.length) ? state.selectedProduct : Object.keys(action.productDraws).filter(product => Boolean(action.productDraws[product].draws.length)).slice(0, 1).pop();
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedProduct
        });
      }
    case FETCH_GAME:
      return _objectSpread(_objectSpread({}, state), {}, {
        loadingGame: true
      });
    case FETCH_GAME_FAILED:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorMessage: action.errorMessage,
        loadingGame: false
      });
    case FETCH_GAME_SUCCESS:
      {
        const buyStopDiff = (new Date(action.game.drawCloseTime).getTime() - new Date(action.game.buyStop).getTime()) / (SECONDS * MINUTES);
        return _objectSpread(_objectSpread({}, state), {}, {
          buyStop: action.game.buyStop,
          name: action.game.name,
          loadingGame: false,
          selectedDraw: action.game.drawNumber,
          selectedProduct: action.game.productName.toLowerCase(),
          buyStopDiff,
          description: action.game.description,
          fractionPrice: action.game.fractionPrice,
          maxFractionsPerMember: action.game.maxFractionsPerMember,
          maxFractionsPerGame: action.game.maxFractions,
          activatedDrawId: action.game.activatedDrawId
        });
      }
    case SELECT_PRODUCT:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedProduct: action.productName,
          errorMessage: null
        });
      }
    case SELECT_DRAW:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          selectedDraw: action.drawNumber,
          name: action.name,
          buyStopDiff: defaultBuyStopDiff,
          isSelectedDrawClosed: false
        });
      }
    case SELECTED_DRAW_CLOSED:
      return action.drawNumber === state.selectedDraw ? _objectSpread(_objectSpread({}, state), {}, {
        isSelectedDrawClosed: true
      }) : state;
    case CREATE_GAME:
    case UPDATE_GAME:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: true
      });
    case CREATE_GAME_FAILED:
    case UPDATE_GAME_FAILED:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case UPDATE_GAME_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        isLoading: false
      });
    case CREATE_GAME_SUCCESS:
    case REMOVE_GAME_SUCCESS:
    case CLEAR_FORM:
      return initialState;
    case CHANGE_BUY_STOP_DIFF:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          buyStopDiff: action.buyStopDiff,
          buyStop: new Date(new Date(state.buyStop).getTime() + (action.buyStopDiff - state.buyStopDiff) * MINUTES * SECONDS)
        });
      }
    case CHANGE_FRACTION_PRICE:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          fractionPrice: action.fractionPrice
        });
      }
    case CHANGE_DESCRIPTION:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          description: action.description
        });
      }
    case CHANGE_MAX_FRACTIONS_PER_MEMBER:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          maxFractionsPerMember: action.maxFractionsPerMember
        });
      }
    case CHANGE_MAX_FRACTIONS_PER_GAME:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          maxFractionsPerGame: action.maxFractionsPerGame
        });
      }
    case LOAD_FROM_LOCAL_STORAGE:
      {
        return _objectSpread(_objectSpread({}, state), action.payload.CreateGame);
      }
    case RESET_VALUES:
      {
        return initialState;
      }
    default:
      return state;
  }
};
setGlobal('svs.components.marketplaceData.store.reducers.createGameReducer', reducer);

 })(window);