(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/create-bet-proposal-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/create-bet-proposal-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  products
} = svs.utils;
const {
  getRowId: getBetProposalRowId
} = svs.components.marketplace.helpers;
const {
  TOGGLE_OUTCOME,
  CLEAR_ROW,
  BET_PROPOSAL_CREATE_INIT,
  BET_PROPOSAL_CREATE_SUCCESS,
  BET_PROPOSAL_CREATE_FAILURE,
  BET_PROPOSAL_UPDATE_INIT,
  BET_PROPOSAL_UPDATE_SUCCESS,
  BET_PROPOSAL_UPDATE_FAILURE,
  BET_PROPOSAL_CLEAR
} = svs.components.marketplaceData.createBetProposal.actions;
const {
  FETCH_BET_PROPOSAL_SUCCESS
} = svs.components.marketplaceData.betProposal.actions;
const {
  FETCH_PRODUCTS_DRAWS_SUCCESS
} = svs.components.marketplaceData.draws.actions;
const {
  LOAD_FROM_LOCAL_STORAGE
} = svs.components.marketplaceData.localStorage.actions;
const intitalState = {
  data: {},
  isCreating: undefined,
  createError: undefined,
  betProposalIsPrivate: undefined
};
const reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 ? arguments[1] : undefined;
  const timestamp = action.timestamp || new Date().getTime();
  switch (action.type) {
    case FETCH_BET_PROPOSAL_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.activatedDrawId]: _objectSpread(_objectSpread({}, state[action.activatedDrawId]), {}, {
          timestamp,
          data: action.betProposal
        })
      });
    case TOGGLE_OUTCOME:
      {
        var _eventRow$participant;
        const {
          activatedDrawId,
          drawNumber,
          eventNumber,
          outcomeNumber,
          productName,
          participantNumber,
          groupId
        } = action;
        const rowId = activatedDrawId || getBetProposalRowId(productName, drawNumber, groupId);
        const row = (state[rowId] || {}).data || {};
        const eventRow = row[eventNumber] || {};
        return _objectSpread(_objectSpread({}, state), {}, {
          [rowId]: {
            timestamp,
            data: _objectSpread(_objectSpread({}, row), {}, {
              [eventNumber]: _objectSpread(_objectSpread({}, eventRow), participantNumber !== undefined ? {
                [participantNumber]: _objectSpread(_objectSpread({}, eventRow[participantNumber]), {}, {
                  [outcomeNumber]: !((_eventRow$participant = eventRow[participantNumber]) !== null && _eventRow$participant !== void 0 && _eventRow$participant[outcomeNumber])
                })
              } : {
                [outcomeNumber]: !eventRow[outcomeNumber]
              })
            })
          }
        });
      }
    case CLEAR_ROW:
      {
        const {
          activatedDrawId,
          productName,
          drawNumber,
          groupId
        } = action;
        const rowId = activatedDrawId || getBetProposalRowId(productName, drawNumber, groupId);
        return _objectSpread(_objectSpread({}, state), {}, {
          [rowId]: _objectSpread(_objectSpread({}, state[rowId]), {}, {
            timestamp,
            data: {}
          })
        });
      }
    case FETCH_PRODUCTS_DRAWS_SUCCESS:
      {
        if (products.helpers.isRacing(action.productName)) {
          return state;
        }
        return Object.keys(state).filter(betProposalKey => {
          const [groupId, productName, drawNumber] = betProposalKey.split('_');
          if (productName !== action.productName) {
            return true;
          }
          if (groupId !== action.groupId) {
            return true;
          }
          let isDrawOpen = false;
          for (let i = 0; i < action.draws.length; ++i) {
            if (action.draws[i].drawNumber !== parseInt(drawNumber, 10)) {
              continue;
            }
            if (action.draws[i].drawStateId <= 2) {
              isDrawOpen = true;
              break;
            }
          }
          return isDrawOpen;
        }).reduce((obj, prop) => _objectSpread(_objectSpread({}, obj), {}, {
          [prop]: state[prop]
        }), {});
      }
    case BET_PROPOSAL_CREATE_INIT:
      {
        const {
          productName,
          drawNumber,
          groupId,
          activatedDrawId,
          betProposalIsPrivate
        } = action;
        const rowId = activatedDrawId || getBetProposalRowId(productName, drawNumber, groupId);
        return _objectSpread(_objectSpread({}, state), {}, {
          [rowId]: _objectSpread(_objectSpread({}, state[rowId]), {}, {
            timestamp,
            isCreating: true,
            createError: undefined,
            betProposalIsPrivate
          })
        });
      }
    case BET_PROPOSAL_CREATE_SUCCESS:
      {
        return intitalState;
      }
    case BET_PROPOSAL_CREATE_FAILURE:
      {
        const {
          productName,
          drawNumber,
          groupId
        } = action;
        const rowId = getBetProposalRowId(productName, drawNumber, groupId);
        return _objectSpread(_objectSpread({}, state), {}, {
          [rowId]: _objectSpread(_objectSpread({}, state[rowId]), {}, {
            timestamp,
            isCreating: false,
            createError: action.error,
            betProposalIsPrivate: false
          })
        });
      }
    case BET_PROPOSAL_UPDATE_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.activatedDrawId]: _objectSpread(_objectSpread({}, state[action.activatedDrawId]), {}, {
          timestamp,
          isUpdating: true,
          updateError: undefined
        })
      });
    case BET_PROPOSAL_UPDATE_SUCCESS:
      {
        return _objectSpread(_objectSpread({}, state), {}, {
          [action.activatedDrawId]: _objectSpread(_objectSpread({}, state[action.activatedDrawId]), {}, {
            timestamp,
            isUpdating: false,
            updateError: undefined
          })
        });
      }
    case BET_PROPOSAL_UPDATE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.activatedDrawId]: _objectSpread(_objectSpread({}, state[action.activatedDrawId]), {}, {
          timestamp,
          isUpdating: false,
          updateError: action.error
        })
      });
    case LOAD_FROM_LOCAL_STORAGE:
      {
        return _objectSpread(_objectSpread({}, state), action.payload.CreateBetProposal);
      }
    case BET_PROPOSAL_CLEAR:
      return intitalState;
    default:
      return state;
  }
};
setGlobal('svs.components.marketplaceData.store.reducers.createBetProposalReducer', reducer);

 })(window);