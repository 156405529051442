(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/browse-games-results-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/browse-games-results-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  payFraction
} = svs.components.marketplaceData.fractionPayment.actions;
const {
  ACTIVATED_DRAW_FETCH_SUCCESS,
  GAME_FETCH_SUCCESS
} = svs.components.marketplaceData.games.actions;
const {
  BROWSE_GAMES_RESULTS_FETCH_INIT,
  BROWSE_GAMES_RESULTS_FETCH_FAILURE,
  BROWSE_GAMES_RESULTS_FETCH_SUCCESS,
  BROWSE_GAMES_RESULTS_FETCH_NEXT_INIT,
  BROWSE_GAMES_RESULTS_FETCH_NEXT_SUCCESS,
  BROWSE_GAMES_RESULTS_FETCH_NEXT_FAILURE
} = svs.components.marketplaceData.results.actions;
const initialState = {
  data: [],
  error: null,
  loading: false,
  offset: 0,
  totalCount: 0
};
const reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case BROWSE_GAMES_RESULTS_FETCH_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        loading: true
      });
    case BROWSE_GAMES_RESULTS_FETCH_NEXT_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: null,
        loading: true
      });
    case BROWSE_GAMES_RESULTS_FETCH_FAILURE:
    case BROWSE_GAMES_RESULTS_FETCH_NEXT_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        error: action.error,
        loading: false
      });
    case BROWSE_GAMES_RESULTS_FETCH_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: action.games,
        error: null,
        loading: false,
        offset: action.offset + action.count,
        totalCount: action.totalCount
      });
    case BROWSE_GAMES_RESULTS_FETCH_NEXT_SUCCESS:
      if (action.offset !== state.offset) {
        return state;
      }
      return _objectSpread(_objectSpread({}, state), {}, {
        data: state.data.concat(action.games),
        error: null,
        loading: false,
        offset: state.offset + action.count,
        totalCount: action.totalCount
      });
    case payFraction.fulfilled:
      {
        const game = state.data.find(g => g.activatedDrawId === action.activatedDrawId && g.groupId === action.groupId);
        if (!game) {
          return state;
        }
        const newGame = _objectSpread(_objectSpread({}, game), {}, {
          currentUserNumberOfShares: game.currentUserNumberOfShares + action.fractionsBought,
          fractions: {
            [action.memberId]: game.fractions[action.memberId] + action.fractionsBought
          }
        });
        const newState = _objectSpread(_objectSpread({}, state), {}, {
          data: state.data.map(g => g.activatedDrawId === action.activatedDrawId && g.groupId === action.groupId ? newGame : g)
        });
        return newState;
      }
    case ACTIVATED_DRAW_FETCH_SUCCESS:
    case GAME_FETCH_SUCCESS:
      {
        const game = state.data.find(g => g.activatedDrawId === action.activatedDrawId && g.groupId === action.groupId);
        if (!game) {
          return state;
        }
        const newGame = _objectSpread(_objectSpread({}, game), action.game);
        const newState = _objectSpread(_objectSpread({}, state), {}, {
          data: state.data.map(g => g.activatedDrawId === action.activatedDrawId && g.groupId === action.groupId ? newGame : g)
        });
        return newState;
      }
    default:
      return state;
  }
};
setGlobal('svs.components.marketplaceData.store.reducers.browseGamesResultsReducer', reducer);

 })(window);