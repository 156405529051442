(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/join-team-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/join-team-reducer.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const {
  helpers,
  urlHelper
} = svs.components.marketplace;
const {
  ACCEPT_INVITE_INIT,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_FAILURE,
  FETCH_INVITATION_INIT,
  FETCH_INVITATION_SUCCESS,
  FETCH_INVITATION_FAILURE,
  FETCH_INVITATION_ALREADY_MEMBER,
  CANCEL_JOIN
} = svs.components.marketplaceData.joinTeam.actions;
const initialState = {
  loading: true,
  redirect: undefined,
  teamName: ''
};
const reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case ACCEPT_INVITE_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        redirect: undefined,
        loading: true
      });
    case ACCEPT_INVITE_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorMessage: action.message,
        loading: false
      });
    case ACCEPT_INVITE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        redirect: helpers.getTeamUrl(action.groupId, state.teamName),
        loading: false,
        groupId: action.groupId
      });
    case FETCH_INVITATION_INIT:
      return _objectSpread(_objectSpread({}, state), {}, {
        redirect: undefined,
        loading: true
      });
    case FETCH_INVITATION_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        redirect: undefined,
        loading: false,
        teamId: action.result.groupId,
        teamName: action.result.groupName,
        maxEntries: action.result.maxEntries,
        openSpots: action.result.openSpots,
        groupId: action.result.groupId
      });
    case FETCH_INVITATION_FAILURE:
      return _objectSpread(_objectSpread({}, state), {}, {
        errorMessage: action.message,
        redirect: urlHelper.getMarketplaceTogetherUrlWithoutRoot(),
        loading: false
      });
    case FETCH_INVITATION_ALREADY_MEMBER:
      return _objectSpread(_objectSpread({}, state), {}, {
        redirect: helpers.getTeamUrl(action.groupId, action.teamName),
        loading: false,
        groupId: action.groupId,
        teamName: action.teamName
      });
    case CANCEL_JOIN:
      return _objectSpread(_objectSpread({}, state), {}, {
        redirect: urlHelper.getMarketplaceTogetherUrlWithoutRoot()
      });
    default:
      return state;
  }
};
setGlobal('svs.components.marketplaceData.store.reducers.joinTeamReducer', reducer);

 })(window);