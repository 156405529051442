(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/games-exclude-fetch.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/games-exclude-fetch.js');
"use strict";


const {
  REMOVE_GAME_SUCCESS
} = svs.components.marketplaceData.games.actions;
const {
  REMOVE_GAME_SUCCESS: CREATE_GAME_REMOVE_GAME_SUCCESS
} = svs.components.marketplaceRetailAdminData.createGame.actions;
const reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case REMOVE_GAME_SUCCESS:
    case CREATE_GAME_REMOVE_GAME_SUCCESS:
      return [...state, action.activatedDrawId];
    default:
      return state;
  }
};
setGlobal('svs.components.marketplaceData.store.reducers.gamesExcludeFetch', reducer);

 })(window);