(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/auth-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/auth-reducer.js');
"use strict";


const {
  userSession
} = svs.core.session.data;
const {
  LOGIN,
  LOGOUT
} = svs.components.marketplaceData.auth.actions;
const initialState = !userSession || !userSession.isAnonymous;

const reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case LOGIN:
      return true;
    case LOGOUT:
      return false;
    default:
      return state;
  }
};
setGlobal('svs.components.marketplaceData.store.reducers.authReducer', reducer);

 })(window);