(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/store.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/store.js');
"use strict";

const {
  browseGames
} = svs.components.marketplaceData;
setGlobal('svs.components.marketplaceData.store.createStore', _ref => {
  let {
    initialState = {},
    middlewares = [],
    reducers
  } = _ref;
  const reducer = RTK.combineReducers(reducers);
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || RTK.compose;
  const enhancer = composeEnhancers(RTK.applyMiddleware(...middlewares));
  const store = RTK.createStore(reducer, initialState, enhancer);
  store.dispatch(browseGames.actions.loadFromQueryString());
  return store;
});

 })(window);