(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/config-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/config-reducer.js');
"use strict";


const reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    default:
      return state;
  }
};
setGlobal('svs.components.marketplaceData.store.reducers.configReducer', reducer);

 })(window);