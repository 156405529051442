(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/middlewares/local-storage-middleware.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/middlewares/local-storage-middleware.js');
"use strict";


let debounceTimer;
const {
  Storage
} = svs.components;
const {
  SELECT_PRODUCT,
  CHANGE_FRACTION_PRICE,
  CHANGE_DESCRIPTION,
  CHANGE_BUY_STOP_DIFF,
  CHANGE_MAX_FRACTIONS_PER_MEMBER,
  CHANGE_MAX_FRACTIONS_PER_GAME
} = svs.components.marketplaceData.createGame.actions;
const {
  TOGGLE_OUTCOME
} = svs.components.marketplaceData.createBetProposal.actions;
const {
  LOCAL_STORAGE_NAME,
  LOCAL_STORAGE_DOMAIN,
  DATA_EXPIRATION
} = svs.components.marketplaceData.localStorage.constants;
const localStorageMiddleware = store => next => action => {
  next(action);

  const interestingActions = [
  SELECT_PRODUCT, CHANGE_FRACTION_PRICE, CHANGE_DESCRIPTION, CHANGE_BUY_STOP_DIFF, CHANGE_MAX_FRACTIONS_PER_MEMBER, CHANGE_MAX_FRACTIONS_PER_GAME,
  TOGGLE_OUTCOME];
  if (interestingActions.indexOf(action.type) === -1) {
    return;
  }
  const {
    CreateBetProposal,
    CreateGame
  } = store.getState();
  const data = {
    CreateBetProposal,
    CreateGame
  };
  if (debounceTimer) {
    clearTimeout(debounceTimer);
  }
  debounceTimer = setTimeout(() => Storage.browser.set(LOCAL_STORAGE_NAME, LOCAL_STORAGE_DOMAIN, data, DATA_EXPIRATION), 300);
};
setGlobal('svs.components.marketplaceData.store.middlewares.localStorageMiddleware', localStorageMiddleware);

 })(window);