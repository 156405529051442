(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/middlewares/create-kibana-middleware.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/middlewares/create-kibana-middleware.js');
"use strict";

const {
  CREATE_GAME,
  CREATE_GAME_SUCCESS,
  CREATE_GAME_FAILED,
  UPDATE_GAME_FAILED,
  UPDATE_GAME_SUCCESS,
  UPDATE_GAME
} = svs.components.marketplaceData.createGame.actions;
const {
  TEAM_CREATE,
  TEAM_CREATE_SUCCESS,
  TEAM_CREATE_FAILURE,
  TEAM_LOOK_UP_NAME_ERROR
} = svs.components.marketplaceData.createTeam.actions;
const {
  ACCEPT_INVITE_INIT,
  ACCEPT_INVITE_SUCCESS,
  ACCEPT_INVITE_FAILURE,
  FETCH_INVITATION_FAILURE
} = svs.components.marketplaceData.joinTeam.actions;
const {
  END_TEAM_START,
  END_TEAM_FAILED,
  END_TEAM_SUCCESS,
  FETCH_NEXT_RETAILERS_AND_TEAMS_FAILED,
  FETCH_TEAM_FAILED
} = svs.components.marketplaceData.teams.actions;
const {
  GAME_FETCH_FAILURE,
  GAMES_FETCH_FAILURE,
  GAMES_HISTORY_FETCH_FAILURE,
  REMOVE_GAME_FAILED,
  FETCH_SETTLED_FAILURE,
  ACTIVATED_DRAW_FETCH_FAILURE
} = svs.components.marketplaceData.games.actions;
const {
  MEMBER_LEAVE_TEAM,
  MEMBER_LEAVE_TEAM_SUCCESS,
  MEMBER_LEAVE_TEAM_FAILURE,
  REMOVE_FROM_TEAM,
  REMOVE_FROM_TEAM_SUCCESS,
  REMOVE_FROM_TEAM_FAILURE,
  TOGGLE_ADMIN,
  TOGGLE_ADMIN_SUCCESS,
  TOGGLE_ADMIN_FAILURE
} = svs.components.marketplaceData.members.actions;
const {
  FRACTION_SUBSCRIPTION_SUCCESS,
  FRACTION_SUBSCRIPTION_UPDATE,
  FRACTION_SUBSCRIPTION_FAIL
} = svs.components.marketplaceData.fractionSubscription.actions;
const {
  INVITE_LINK_GENERATE,
  INVITE_LINK_GENERATE_FAILURE,
  INVITE_LINK_GENERATE_SUCCESS
} = svs.components.marketplaceData.inviteMembers.actions;
const {
  CHAT_MARK_READ_FAILURE
} = svs.components.chat.chatData.actions;
const {
  CHAT_GET_UNREAD_BY_ROOM_FAILURE
} = svs.components.chat.chatData.actions;
const {
  FETCH_BET_PROPOSAL_FAILURE
} = svs.components.marketplaceData.betProposal.actions;
const {
  BET_PROPOSAL_CREATE_FAILURE,
  BET_PROPOSAL_UPDATE_FAILURE
} = svs.components.marketplaceData.createBetProposal.actions;
const {
  payFraction
} = svs.components.marketplaceData.fractionPayment.actions;
const {
  FETCH_ALL_DRAWS_FAILURE,
  FETCH_PRODUCTS_DRAWS_FAILURE,
  FETCH_SINGLE_DRAW_FAILURE
} = svs.components.marketplaceData.draws.actions;
const {
  log
} = svs.core;

function getLogMethod(action) {
  let logMethod = 'debug';
  if (action && action.error && action.error.code) {
    logMethod = 'info';
  }
  return logMethod;
}
const getErrorMessage = errObj => {
  let errorCode = 0;
  if (errObj && errObj.code) {
    errorCode = errObj.code;
  }
  return "code: ".concat(errorCode, ", message: ").concat(errObj === null || errObj === void 0 ? void 0 : errObj.message);
};
function createKibanaMiddleware() {
  const logger = new log.Logger('marketplace:client');
  const kibanaMiddleware = () => next => action => {
    const logMethod = getLogMethod(action);
    try {
      switch (action.type) {
        case INVITE_LINK_GENERATE:
          logger.debug("Attempt to generate invitation link (groupId: ".concat(action.groupId, ")"));
          break;
        case INVITE_LINK_GENERATE_SUCCESS:
          logger.debug("\u2705 Successfully generated invitation link (groupId: ".concat(action.groupId, ", token: ").concat(action.token, ")"));
          break;
        case INVITE_LINK_GENERATE_FAILURE:
          logger[logMethod]("\u274C Failed to generate invitation link (groupId: ".concat(action.groupId, ", ").concat(getErrorMessage(action.error), ")"));
          break;
        case FETCH_INVITATION_FAILURE:
          logger[logMethod]("\u274C Failed to fetch invitation link (invitationToken: ".concat(action.invitationToken, ", ").concat(getErrorMessage(action.error), ")"));
          break;
        case FRACTION_SUBSCRIPTION_UPDATE:
          logger.debug("\u2705 Successfully updated fractionsubscribe to team: (groupId: ".concat(action.groupId, ")"));
          break;
        case FRACTION_SUBSCRIPTION_SUCCESS:
          logger.debug("\u2705 Successfully fractionsubscribe to team: (groupId: ".concat(action.groupId, ",)"));
          break;
        case FRACTION_SUBSCRIPTION_FAIL:
          logger[logMethod]("\u274C Failed to fractionsubscribe to team: (".concat(getErrorMessage(action.error), ")"));
          break;
        case TOGGLE_ADMIN:
          if (action.isCurrentlyAdmin) {
            logger.debug("Attempt to remove admin rights on member (memberId: ".concat(action.memberId, ", groupId: ").concat(action.groupId, ")"));
          } else {
            logger.debug("Attempt to give admin rights to member (memberId: ".concat(action.memberId, ", groupId: ").concat(action.groupId, ")"));
          }
          break;
        case TOGGLE_ADMIN_SUCCESS:
          if (action.isCurrentlyAdmin) {
            logger.debug("\u2705 Successfully removed admin rights on member (memberId: ".concat(action.memberId, ", groupId: ").concat(action.groupId, ")"));
          } else {
            logger.debug("\u2705 Successfully gave admin rights to member (memberId: ".concat(action.memberId, ", groupId: ").concat(action.groupId, ")"));
          }
          break;
        case TOGGLE_ADMIN_FAILURE:
          if (action.isCurrentlyAdmin) {
            logger[logMethod]("\u274C Failed to remove admin rights on member (memberId: ".concat(action.memberId, ", groupId: ").concat(action.groupId, ", ").concat(getErrorMessage(action.error), ")"));
          } else {
            logger[logMethod]("\u274C Failed to give admin rights to member (memberId: ".concat(action.memberId, ", groupId: ").concat(action.groupId, ", ").concat(getErrorMessage(action.error), ")"));
          }
          break;
        case REMOVE_FROM_TEAM:
          logger.debug("Attempt to remove member from team (memberId: ".concat(action.memberId, ", groupId: ").concat(action.groupId, ")"));
          break;
        case REMOVE_FROM_TEAM_SUCCESS:
          logger.debug("\u2705 Successfully removed member from team (memberId: ".concat(action.memberId, ", groupId: ").concat(action.groupId, ")"));
          break;
        case REMOVE_FROM_TEAM_FAILURE:
          logger[logMethod]("\u274C Failed to remove member from team (memberId: ".concat(action.memberId, ", groupId: ").concat(action.groupId, ", ").concat(getErrorMessage(action.error), ")"));
          break;
        case MEMBER_LEAVE_TEAM:
          logger.debug("Attempt to leave team (groupId: ".concat(action.groupId, ")"));
          break;
        case MEMBER_LEAVE_TEAM_SUCCESS:
          logger.debug("\u2705 Successfully left team (groupId: ".concat(action.groupId, ")"));
          break;
        case MEMBER_LEAVE_TEAM_FAILURE:
          logger[logMethod]("\u274C Failed to leave team (groupId: ".concat(action.groupId, ", ").concat(getErrorMessage(action.error), ")"));
          break;
        case END_TEAM_START:
          logger.debug("Attempt to delete team (groupId: ".concat(action.groupId, ")"));
          break;
        case END_TEAM_SUCCESS:
          logger.debug("\u2705 Successfully deleted team (groupId: ".concat(action.groupId, ")"));
          break;
        case END_TEAM_FAILED:
          logger[logMethod]("\u274C Failed to delete team (groupId: ".concat(action.groupId, ", ").concat(getErrorMessage(action.error), ")"));
          break;
        case FETCH_NEXT_RETAILERS_AND_TEAMS_FAILED:
          logger[logMethod]("\u274C Failed to fetch next retailers and teams (".concat(getErrorMessage(action.error), ")"));
          break;
        case FETCH_TEAM_FAILED:
          logger[logMethod]("\u274C Failed to fetch team (groupId: ".concat(action.groupId, ", ").concat(getErrorMessage(action.error), ")"));
          break;
        case CREATE_GAME:
          logger.debug("Attempt to create game (groupId: ".concat(action.groupId, ", product: ").concat(action.productName, ", drawNumber: ").concat(action.drawNumber, ")"));
          break;
        case CREATE_GAME_SUCCESS:
          logger.debug("\u2705 Successfully created game (groupId: ".concat(action.groupId, ", product: ").concat(action.productName, ", drawNumber: ").concat(action.drawNumber, ",\n            activatedDrawId: ").concat(action.game.activatedDrawId, ")"));
          break;
        case CREATE_GAME_FAILED:
          logger[logMethod]("\u274C Failed to create game (groupId: ".concat(action.groupId, ", product: ").concat(action.productName, ", drawNumber: ").concat(action.drawNumber, ", ").concat(getErrorMessage(action.error), ")"));
          break;
        case UPDATE_GAME:
          logger.debug("Attempt to update game (groupId: ".concat(action.groupId, ", activatedDrawId: ").concat(action.activatedDrawId, ")"));
          break;
        case UPDATE_GAME_SUCCESS:
          logger.debug("\u2705 Updated game successfully (groupId: ".concat(action.game.groupId, ", activatedDrawId: ").concat(action.game.activatedDrawId, ")"));
          break;
        case UPDATE_GAME_FAILED:
          logger[logMethod]("\u274C Failed to update game (groupId: ".concat(action.groupId, ", activatedDrawId: ").concat(action.activatedDrawId, ", ").concat(getErrorMessage(action.error), ")"));
          break;
        case TEAM_CREATE:
          logger.debug("Attempt to create team (name: ".concat(action.teamName, ")"));
          break;
        case TEAM_CREATE_SUCCESS:
          logger.debug("\u2705 Created team successfully (groupId: ".concat(action.team.id, ", name: ").concat(action.team.name, ", token: ").concat(action.team.token, ")"));
          break;
        case TEAM_CREATE_FAILURE:
          logger[logMethod]("\u274C Failed to create team (teamName: ".concat(action.teamName, ", ").concat(getErrorMessage(action.error), ")"));
          break;
        case TEAM_LOOK_UP_NAME_ERROR:
          logger[logMethod]("\u274C Failed to look up teamName (teamName: ".concat(action.teamName, ", ").concat(getErrorMessage(action.error), ")"));
          break;
        case ACCEPT_INVITE_INIT:
          logger.debug("Accept invite attempt (token: ".concat(action.invitationToken, ")"));
          break;
        case ACCEPT_INVITE_SUCCESS:
          logger.debug("\u2705 Accept invite successfully (token: ".concat(action.invitationToken, ")"));
          break;
        case ACCEPT_INVITE_FAILURE:
          {
            logger[logMethod]("\u274C Failed to accept an invite (token: ".concat(action.invitationToken, ", code: ").concat(action.err.code, ", message: ").concat(action.err.message, ")"));
            break;
          }
        case CHAT_MARK_READ_FAILURE:
          {
            logger[logMethod]("\u274C Failed to mark ".concat(action.messageId, " as latestRead in ").concat(action.roomId, " (message: ").concat(action.error, ")"));
            break;
          }
        case CHAT_GET_UNREAD_BY_ROOM_FAILURE:
          {
            logger[logMethod]("\u274C Failed to get unread for ".concat(action.roomId, " (message: ").concat(action.error, ")"));
            break;
          }
        case ACTIVATED_DRAW_FETCH_FAILURE:
          {
            logger[logMethod]("\u274C Failed to fetch activatedDraw (containerId: ".concat(action.containerId, ", activatedDrawId: ").concat(action.activatedDrawId, ", ").concat(getErrorMessage(action.error), ")"));
            break;
          }
        case GAME_FETCH_FAILURE:
          {
            logger[logMethod]("\u274C Failed to fetch game (containerId: ".concat(action.containerId, ", ").concat(getErrorMessage(action.error), ")"));
            break;
          }
        case FETCH_SETTLED_FAILURE:
          {
            logger[logMethod]("\u274C Failed to fetch settled bet (activatedDrawId: ".concat(action.activatedDrawId, ", ").concat(getErrorMessage(action.error), ")"));
            break;
          }
        case GAMES_FETCH_FAILURE:
          {
            logger[logMethod]("\u274C Failed to fetch games for team (groupId: ".concat(action.groupId, ", ").concat(getErrorMessage(action.error), ")"));
            break;
          }
        case REMOVE_GAME_FAILED:
          logger[logMethod]("\u274C Failed to remove game (activatedDrawId: ".concat(action.activatedDrawId, ", ").concat(getErrorMessage(action.error), ")"));
          break;
        case GAMES_HISTORY_FETCH_FAILURE:
          logger[logMethod]("\u274C Failed to fetch historical games for team (groupId: ".concat(action.groupId, ", ").concat(getErrorMessage(action.error), ")"));
          break;
        case FETCH_BET_PROPOSAL_FAILURE:
          {
            logger[logMethod]("\u274C Failed to fetch bet-proposal for game (activatedDrawId: ".concat(action.activatedDrawId, ", ").concat(getErrorMessage(action.error), ")"));
            break;
          }
        case BET_PROPOSAL_UPDATE_FAILURE:
          {
            logger[logMethod]("\u274C Failed to update bet-proposal for game (groupId: ".concat(action.groupId, ", activatedDrawId: ").concat(action.activatedDrawId, ", ").concat(getErrorMessage(action.error), ")"));
            break;
          }
        case BET_PROPOSAL_CREATE_FAILURE:
          {
            logger[logMethod]("\u274C Failed to update (create) bet-proposal for game (activatedDrawId: ".concat(action.activatedDrawId, ", ").concat(getErrorMessage(action.error), ")"));
            break;
          }
        case payFraction.fulfilled:
          {
            logger.debug("\u2705 Share successfully bought (groupId: ".concat(action.groupId, " memberId: ").concat(action.memberId, " activatedDrawId: ").concat(action.activatedDrawId, " fractionsBought: ").concat(action.fractionsBought, ")"));
            break;
          }
        case payFraction.rejected:
          {
            logger[logMethod]("\u274C Failed to buy a share (groupId: ".concat(action.groupId, " memberId: ").concat(action.memberId, " containerId: ").concat(action.containerId, " ").concat(getErrorMessage(action.error), ")"));
            break;
          }
        case FETCH_ALL_DRAWS_FAILURE:
          logger[logMethod]("\u274C Failed to fetch all draws (".concat(getErrorMessage(action.error), ")"));
          break;
        case FETCH_PRODUCTS_DRAWS_FAILURE:
          logger[logMethod]("\u274C Failed to fetch draws for product ".concat(action.productName, " (").concat(getErrorMessage(action.error), ")"));
          break;
        case FETCH_SINGLE_DRAW_FAILURE:
          logger[logMethod]("\u274C Failed to fetch single draw ".concat(action.productName, " at drawNumber ").concat(action.drawNumber, " (").concat(getErrorMessage(action.error), ")"));
          break;
        default:
      }
    } catch (e) {
      logger.error('Failed logging to kibana:', e, JSON.stringify(action));
    } finally {
      next(action);
    }
  };
  return kibanaMiddleware;
}
setGlobal('svs.components.marketplaceData.store.middlewares.createKibanaMiddleware', createKibanaMiddleware);

 })(window);