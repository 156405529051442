(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/member-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/member-reducer.js');
"use strict";

function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
const {
  TEAM_CREATE_SUCCESS
} = svs.components.marketplaceData.createTeam.actions;
const {
  constants: {
    roleWeights
  },
  actions: {
    MEMBER_LEAVE_TEAM_SUCCESS,
    REMOVE_FROM_TEAM_SUCCESS,
    TOGGLE_ADMIN_SUCCESS
  }
} = svs.components.marketplaceData.members;
const {
  END_TEAM_SUCCESS,
  FETCH_TEAM_FINISHED,
  FETCH_NEXT_RETAILERS_AND_TEAMS_SUCCESS
} = svs.components.marketplaceData.teams.actions;
const {
  roles,
  statuses
} = svs.components.marketplaceData.auth;
const memberReducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case TEAM_CREATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.team.id]: [action.member]
      });
    case END_TEAM_SUCCESS:
      {
        const _ref = "".concat(action.groupId),
          {
            [_ref]: _
          } = state,
          rest = _objectWithoutProperties(state, [_ref].map(_toPropertyKey));
        return rest;
      }
    case MEMBER_LEAVE_TEAM_SUCCESS:
      {
        if (action.isParticipatingInGame) {
          const member = state[action.groupId].find(member => member.id === action.memberId);
          return _objectSpread(_objectSpread({}, state), {}, {
            ["".concat(action.groupId)]: [_objectSpread(_objectSpread({}, member), {}, {
              name: 'Före detta medlem',
              status: statuses.STATUSES.PENDING_REMOVAL,
              role: roles.getRole(member.role, statuses.STATUSES.PENDING_REMOVAL)
            })]
          });
        }
        const _ref2 = "".concat(action.groupId),
          {
            [_ref2]: _
          } = state,
          rest = _objectWithoutProperties(state, [_ref2].map(_toPropertyKey));
        return rest;
      }
    case FETCH_NEXT_RETAILERS_AND_TEAMS_SUCCESS:
      {
        const usersByTeam = {};
        action.teams.forEach(team => {
          let stateMe = {};
          let indexMe = -1;
          if (state[team.id]) {
            for (let i = 0; i < state[team.id].length; i++) {
              if (state[team.id][i].isMe) {
                stateMe = state[team.id][i];
                indexMe = i;
                break;
              }
            }
          }
          if (indexMe < 0) {
            usersByTeam[team.id] = [team.currentUser];
          }
          if (indexMe > -1) {
            usersByTeam[team.id] = state[team.id];
            usersByTeam[team.id][indexMe] = _objectSpread(_objectSpread({}, stateMe), team.currentUser);
          }
        });
        return _objectSpread(_objectSpread({}, state), usersByTeam);
      }
    case FETCH_TEAM_FINISHED:
      {
        const {
          id
        } = action.retailer || action.team;
        return _objectSpread(_objectSpread({}, state), {}, {
          [id]: action.members.sort((a, b) => roleWeights[a.role] - roleWeights[b.role] || a.name.localeCompare(b.name))
        });
      }

    case REMOVE_FROM_TEAM_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.groupId]: state[action.groupId].filter(member => member.id !== action.memberId)
      });
    case TOGGLE_ADMIN_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        [action.groupId]: state[action.groupId].map(member => {
          if (member.id === action.memberId) {
            const isCurrentlyAdmin = member.role === roles.ROLES.ADMIN;
            if (isCurrentlyAdmin) {
              member.role = roles.ROLES.MEMBER;
            } else {
              member.role = roles.ROLES.ADMIN;
            }
          }
          return member;
        })
      });
    default:
      return state;
  }
};
setGlobal('svs.components.marketplaceData.store.reducers.memberReducer', memberReducer);

 })(window);