(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/funds.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/funds.js');
"use strict";


const {
  createReducer
} = RTK;
const {
  fetchFunds,
  fetchJackpots
} = svs.components.marketplaceData.funds.actions;


const fundsReducer = createReducer({
  data: [],
  error: null,
  isLoading: false,
  lastFetch: null
}, builder => {
  builder.addCase(fetchFunds.pending, state => {
    state.isLoading = true;
  }).addCase(fetchFunds.fulfilled, (state, action) => {
    state.data = action.payload;
    state.isLoading = false;
    state.lastFetch = new Date();
  }).addCase(fetchFunds.rejected, (state, action) => {
    state.error = action.error;
    state.isLoading = false;
    state.lastFetch = null; 
  });
});

const jackpotsReducer = createReducer({
  data: [],
  error: null,
  isLoading: false
}, builder => {
  builder.addCase(fetchJackpots.pending, state => {
    state.isLoading = true;
  }).addCase(fetchJackpots.fulfilled, (state, action) => {
    state.data = action.payload;
    state.isLoading = false;
    state.lastFetch = new Date();
  }).addCase(fetchJackpots.rejected, (state, action) => {
    state.error = action.error;
    state.isLoading = false;
    state.lastFetch = null; 
  });
});
setGlobal('svs.components.marketplaceData.store.reducers.funds', {
  jackpotsReducer,
  fundsReducer
});

 })(window);