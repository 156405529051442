(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/middlewares/search-query-middleware.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/middlewares/search-query-middleware.js');

'use strict';

const {
  analytics
} = svs.components;
let debounceTimer;
const {
  FILTER_QUERY_NAME,
  FILTER_UPDATE,
  QS_NAME
} = svs.components.marketplaceData.browseGames.helpers.constants;
const {
  QS_FRACTION_DATA
} = svs.components.marketplaceShared.sharedConstants.constants;
const {
  BROWSE_GAMES_RESULTS_FETCH_INIT,
  BROWSE_GAMES_RESULTS_FETCH_SUCCESS
} = svs.components.marketplaceData.results.actions;
const {
  queryString
} = svs.components.marketplaceData.browseGames.helpers;
const TRACK_SEARCH_CATEGORY = 'spela-tillsammans';
const TRACK_SEARCH_DEBOUNCE_TIMEOUT = 2000;
const searchQueryMiddleware = () => next => action => {
  var _action$updatedFilter;
  next(action);
  const interestingActions = [FILTER_UPDATE, BROWSE_GAMES_RESULTS_FETCH_INIT, BROWSE_GAMES_RESULTS_FETCH_SUCCESS];
  if (interestingActions.indexOf(action.type) === -1) {
    return;
  }
  const qs = queryString.querystringHandler(action);
  const addQtoString = (key, string) => {
    const currentQS = new URLSearchParams(window.location.search);
    if (currentQS.has(key) && !qs.has(key)) {
      return "".concat(string, "&").concat(key, "=").concat(currentQS.get(key));
    }
    return string;
  };
  qs.delete(QS_FRACTION_DATA);
  let qsString = qs.toString();
  const browseGames = window.location.pathname === "".concat(svs.core.urlMapping.get('marketplace'), "/hitta-andelsspel");
  const retailer = window.location.pathname.indexOf("".concat(svs.core.urlMapping.get('marketplace'), "/ombudslag/")) > -1;
  if (retailer) {
    qsString = addQtoString('origin', qsString);
    qsString = addQtoString('returnUrl', qsString);
  }
  if (!browseGames) {
    qsString = addQtoString('fractionData', qsString);
  }
  if (browseGames || retailer) {
    if (qsString) {
      history.replaceState(null, null, "?".concat(qsString));
    } else if (qsString === '') {
      history.replaceState(null, null, window.location.pathname);
    }
  }
  if ((action === null || action === void 0 || (_action$updatedFilter = action.updatedFilter) === null || _action$updatedFilter === void 0 ? void 0 : _action$updatedFilter.key) === FILTER_QUERY_NAME) {
    var _action$updatedFilter2;
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    if ((action === null || action === void 0 || (_action$updatedFilter2 = action.updatedFilter) === null || _action$updatedFilter2 === void 0 ? void 0 : _action$updatedFilter2.key) === FILTER_QUERY_NAME) {
      if (action.updatedFilter.value === '') {
        qs.delete(QS_NAME);
      } else {
        qs.set(QS_NAME, action.updatedFilter.value.name);
      }
    }
    const searchTerm = qs.get(QS_NAME);
    if (!searchTerm) {
      next(action);
      return;
    }
    debounceTimer = setTimeout(() => {
      analytics.trackSearch(decodeURIComponent(searchTerm), TRACK_SEARCH_CATEGORY);
    }, TRACK_SEARCH_DEBOUNCE_TIMEOUT);
  }
};
setGlobal('svs.components.marketplaceData.store.middlewares.searchQueryMiddleware', searchQueryMiddleware);

 })(window);