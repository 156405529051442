(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/middlewares/error-status-middleware.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/middlewares/error-status-middleware.js');
"use strict";


const {
  actions: gamesActions
} = svs.components.marketplaceData.games;
const {
  actions: fractionPaymentActions
} = svs.components.marketplaceData.fractionPayment;
const {
  actions: createGameActions
} = svs.components.marketplaceData.createGame;
const {
  constants,
  getWarning
} = svs.components.marketplaceData.errorCodeHandler;
const {
  getGameByActivatedDrawId,
  getBrowseGame
} = svs.components.marketplaceData.selectors.games;
setGlobal('svs.components.marketplaceData.store.middlewares.errorStatusMiddleware', store => next => action => {
  next(action);
  if (action.error) {
    if (action.type === createGameActions.UPDATE_GAME_FAILED && getWarning(action.error.code) === constants.warnings.INVALID_PARAM) {
      const game = getGameByActivatedDrawId(store.getState(), action.groupId, action.activatedDrawId) || getBrowseGame(store.getSTate(), {
        activatedDrawId: action.activatedDrawId,
        groupId: action.groupId
      });
      return store.dispatch(createGameActions.fetchGame(action.groupId, game.containerId));
    }
    if (action.type === fractionPaymentActions.payFraction.rejected && getWarning(action.error.code) === constants.warnings.NO_SHARES_AVAILABLE) {
      const game = getGameByActivatedDrawId(store.getState(), action.groupId, action.activatedDrawId);
      if (game) {
        return store.dispatch(gamesActions.fetchGameAction(action.groupId, game.containerId));
      }
    }
  }
});

 })(window);