(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/middlewares/ga-middleware.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/middlewares/ga-middleware.js');
"use strict";


const {
  CREATE_GAME_SUCCESS
} = svs.components.marketplaceData.createGame.actions;
const {
  TEAM_CREATE_SUCCESS
} = svs.components.marketplaceData.createTeam.actions;
const {
  ACCEPT_INVITE_INIT
} = svs.components.marketplaceData.joinTeam.actions;
const {
  FRACTION_SUBSCRIPTION_UPDATE
} = svs.components.marketplaceData.fractionSubscription.actions;
const {
  MEMBER_LEAVE_TEAM
} = svs.components.marketplaceData.members.actions;
const {
  MAX_FRACTIONS_PER_GAME
} = svs.components.marketplaceData.createGame.constants;
const {
  BET_PROPOSAL_CREATE_SUCCESS
} = svs.components.marketplaceData.createBetProposal.actions;
const {
  getGameByActivatedDrawId,
  getBrowseGame
} = svs.components.marketplaceData.selectors.games;
const {
  createGameReducer
} = svs.components.marketplaceData.store.reducers;
const {
  trackEvent
} = svs.components.analytics;
const {
  getDisplayName,
  getProdName
} = svs.components.lbUtils.sportProducts;
const defaultCreateGameState = createGameReducer(undefined, {});
const TYPE_CREATE_GAME = 'TYPE_CREATE_GAME';
const TYPE_CREATE_TEAM = 'TYPE_CREATE_TEAM';
const TYPE_JOIN_TEAM_ACCEPT = 'TYPE_JOIN_TEAM_ACCEPT';
const TYPE_LEAVE_TEAM_ACCEPT = 'TYPE_LEAVE_TEAM_ACCEPT';
const TYPE_FRACTION_SUBSCRIBE = 'TYPE_FRACTION_SUBSCRIBE';
const CATEGORY_MARKETPLACE = 'Tillsammans';
const {
  buyStops: buyStopConfigs
} = svs.marketplace ? svs.marketplace.data.initialState.Config : svs.marketplace_retail_admin && svs.marketplace_retail_admin.data.initialState.Config || {};
function getActivatedDrawId(action) {
  if (action.type === CREATE_GAME_SUCCESS) {
    return action.game.activatedDrawId;
  }
  return action.activatedDrawId;
}
const getActionType = action => {
  if (action.type === CREATE_GAME_SUCCESS) {
    if (action.hasBetProposal) {
      return null;
    }
    return TYPE_CREATE_GAME;
  }
  if (action.type === BET_PROPOSAL_CREATE_SUCCESS && Object.keys(action.betProposal).length > 0) {
    return TYPE_CREATE_GAME;
  }
  if (action.type === TEAM_CREATE_SUCCESS) {
    return TYPE_CREATE_TEAM;
  }
  if (action.type === ACCEPT_INVITE_INIT) {
    return TYPE_JOIN_TEAM_ACCEPT;
  }
  if (action.type === MEMBER_LEAVE_TEAM) {
    return TYPE_LEAVE_TEAM_ACCEPT;
  }
  if (action.type === FRACTION_SUBSCRIPTION_UPDATE) {
    return TYPE_FRACTION_SUBSCRIBE;
  }
  return null;
};
function buildEventCreateTeam() {
  return {
    category: CATEGORY_MARKETPLACE,
    action: 'Created team'
  };
}
function buildEventFractionPayment(action, store) {
  const groupId = action.groupId;
  const activatedDrawId = getActivatedDrawId(action);
  const game = getGameByActivatedDrawId(store.getState(), groupId, activatedDrawId) || getBrowseGame(store.getState(), {
    groupId,
    activatedDrawId
  });
  const productName = getProdName(game.productId);
  return {
    category: 'Tillsammans',
    action: 'Reserve share',
    opt_label: "".concat(productName, "-").concat(game.groupId)
  };
}
function generateBuyStopLabel(buyStopDiff) {
  if (!buyStopConfigs) {
    return null;
  }
  return buyStopConfigs.find(_ref => {
    let {
      minutes
    } = _ref;
    return buyStopDiff === minutes;
  }).label;
}
function countBetPropsalSigns(betProposal) {
  if (!betProposal) {
    return 0;
  }
  return Object.keys(betProposal).reduce((count, row) => {
    count += Object.keys(betProposal[row]).filter(key => betProposal[row][key]).length;
    return count;
  }, 0);
}
function getAdvancedSelections(game, betProposal) {
  const selections = [];
  if (game.maxFractionsPerMember !== defaultCreateGameState.maxFractionsPerMember) {
    selections.push("Shares per member:".concat(game.maxFractionsPerMember));
  }
  if (game.maxFractionsPerGame !== MAX_FRACTIONS_PER_GAME) {
    selections.push("Shares per game:".concat(game.maxFractionsPerGame));
  }
  const buyStopDiff = game.buyStopDiff;
  if (buyStopDiff !== defaultCreateGameState.buyStopDiff) {
    selections.push("Time to purchase:".concat(generateBuyStopLabel(buyStopDiff)));
  }
  if (countBetPropsalSigns(betProposal) >= 3) {
    selections.push("Predefined Rows:".concat(countBetPropsalSigns(betProposal)));
  }
  return selections;
}
function buildEventCreatedGame(action, store) {
  const game = store.getState().Games[action.groupId].data.find(game => game.activatedDrawId === getActivatedDrawId(action));
  const advancedSelections = getAdvancedSelections(game, action.betProposal);
  return {
    category: CATEGORY_MARKETPLACE,
    action: "Open ".concat(advancedSelections.length === 0 ? 'Basic' : 'Advanced', " ").concat(getDisplayName(game.productId)),
    opt_label: advancedSelections.join('-'),
    opt_value: game.fractionPrice
  };
}

function buildEventJoinTeam(action, store) {
  return {
    category: CATEGORY_MARKETPLACE,
    action: 'join_team'
  };
}
function buildEventLeaveTeam() {
  return {
    category: CATEGORY_MARKETPLACE,
    action: 'leave_team'
  };
}

function buildEventFractionSubscribe(action, store) {
  return {
    category: CATEGORY_MARKETPLACE,
    action: 'subscribe_team',
    opt_label: action.value
  };
}
function handleAction(action, store) {
  const actionType = getActionType(action);
  let event;
  if (!actionType) {
    return;
  }
  if (actionType === TYPE_CREATE_GAME) {
    event = buildEventCreatedGame(action, store);
  } else if (actionType === TYPE_CREATE_TEAM) {
    event = buildEventCreateTeam();
  } else if (actionType === TYPE_JOIN_TEAM_ACCEPT) {
    event = buildEventJoinTeam(action, store);
  } else if (actionType === TYPE_LEAVE_TEAM_ACCEPT) {
    event = buildEventLeaveTeam();
  } else if (actionType === TYPE_FRACTION_SUBSCRIBE) {
    event = buildEventFractionSubscribe(action, store);
  }
  trackEvent(event);
}
const gaMiddleware = store => next => action => {
  next(action);
  handleAction(action, store);
};

setGlobal('svs.components.marketplaceData.store.middlewares', {
  gaMiddleware,
  gaMiddlewareConstants: {
    CATEGORY_MARKETPLACE
  }
});

 })(window);