(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/reducers/dialog-wrapper-reducer.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/reducers/dialog-wrapper-reducer.js');
"use strict";


const {
  SHOW_DIALOG,
  CLOSE_DIALOG
} = svs.components.marketplaceData.dialogWrapper.actions;
const initialState = {
  buttons: [],
  text: '',
  title: ''
};
const reducer = function () {
  let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  let action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case SHOW_DIALOG:
      return {
        title: action.title,
        text: action.text,
        buttons: action.buttons,
        dialogType: action.dialogType,
        icon: action.icon
      };
    case CLOSE_DIALOG:
      return {
        title: '',
        text: '',
        buttons: []
      };
    default:
      return state;
  }
};
setGlobal('svs.components.marketplaceData.store.reducers.dialogWrapperReducer', reducer);

 })(window);