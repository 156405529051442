(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-data/store/assets/middlewares/browse-games-middleware.js') >= 0) return;  svs.modules.push('/components/marketplace-data/store/assets/middlewares/browse-games-middleware.js');
"use strict";

const DEBOUNCE_TIMEOUT = 600;
const {
  fetchResultsAction
} = svs.components.marketplaceData.results.actions;
let timer = null;
setGlobal('svs.components.marketplaceData.store.middlewares.browseGamesMiddleware', store => next => action => {
  next(action);
  const {
    FILTER_UPDATE
  } = svs.components.marketplaceData.browseGames.actions;
  const interestingActions = [FILTER_UPDATE];
  if (interestingActions.indexOf(action.type) === -1) {
    return;
  }
  const groupId = action.groupId;
  if (timer !== null) {
    clearTimeout(timer);
    timer = null;
  }
  if (timer === null) {
    timer = setTimeout(() => {
      timer = null;

      store.dispatch(fetchResultsAction(groupId));
    }, DEBOUNCE_TIMEOUT);
  }
});

 })(window);